const extensions = {
  "": "unknown",
  "audio": "audio",
  "video": "video",
  "text": "text",
  "archive": "archive",

  ".jpg": "jpg",
  ".jpe": "jpg",
  ".jpeg": "jpg",
  ".jfif": "jpg",
  ".png": "png",
  ".gif": "gif",
  ".tiff": "tiff",
  ".svg": "svg",
  ".psd": "psd",
  ".ai": "ai",
  ".dwg": "dwg",

  ".iso": "iso",
  ".mdf": "mdf",
  ".nrg": "nrg",

  ".zip": "zip",
  ".7z": "7z",
  ".7zip": "7z",
  ".arj": "arj",
  ".rar": "rar",
  ".gz": "archive",
  ".gzip": "archive",
  ".bz2": "archive",
  ".bzip2": "archive",
  ".tar": "archive",

  ".xls": "xls",
  ".doc": "doc",
  ".pdf": "pdf",
  ".ppt": "ppt",
  ".xlsx": "xls",
  ".docx": "doc",
  ".pdfx": "pdf",
  ".pptx": "ppt",
  ".rtf": "rtf",
  ".txt": "txt",
  ".md": "text",
  ".markdown": "text",

  ".avi": "avi",
  ".mp2": "mp2",
  ".mp3": "mp3",
  ".mp4": "mp4",
  ".fla": "fla",
  ".mxf": "mxf",
  ".wav": "wav",
  ".wma": "wma",
  ".aac": "aac",
  ".flac": "flac",

  ".css": "css",
  ".csv": "csv",
  ".html": "html",
  ".json": "json",
  ".js": "js",
  ".xml": "xml",

  ".dbf": "dbf",
  ".exe": "exe"
};
let unknown = extensions[''];

function isString(value) {
  return (typeof value === 'string') ||
      (Object.prototype.toString.call(value) === '[object String]');
}

export function getIcon(filename, type) {
  // Extract extension from the filename
  let ext = isString(filename) ? filename.substr(filename.lastIndexOf('.') + 1).toLowerCase() : '';

  // Validate type - it should be 'svg' or '.svg'
  type = isString(type) ? type.toLowerCase() : '';
  if (type.charAt(0) === '.') {
    type = type.substr(1, type.length);
  }
  if (['svg'].indexOf(type) >= 0) {
    type = '.' + type;
  } else {
    type = '';
  }

  return (extensions[`.${ext}`] || unknown) + type;
}

export function formatSize(bytes) {
  if (bytes === 0) {
    return "0 B";
  }
  let k = 1000,
    dm = 3,
    sizes = ["B", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"],
    i = Math.floor(Math.log(bytes) / Math.log(k));

  return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
}

export function isImage(type) {
  return /^image\//.test(type);
}

export function useFiles() {
  return {
    formatSize,
    isImage,
    getIcon
  };
}
