<script>
import { computed, defineComponent, watchEffect, ref } from "vue";

import { useStore } from "vuex";
import { useFiles } from "@/compositions/useFiles";

import EditorJS from "@editorjs/editorjs";
import Header from "@editorjs/header";
import SimpleImage from "@editorjs/simple-image";
import List from "@editorjs/list";
import Checklist from "@editorjs/checklist";
import Quote from "@editorjs/quote";
import Marker from "@editorjs/marker";
import Delimiter from "@editorjs/delimiter";
import InlineCode from "@editorjs/inline-code";
import LinkTool from "@editorjs/link";
import Embed from "@editorjs/embed";
import Table from "@editorjs/table";

export default defineComponent({
  name: "ProjectShowOverview",
  setup() {
    const store = useStore();
    const files = useFiles();

    const project = computed(() => store.getters["project/project"]);
    const editor = ref(null);
    watchEffect(() => {
      if (project.value.id) {
        /**
         * Create an instance
         */
        editor.value = new EditorJS({
          holder: "editor",
          readOnly: true,
          /**
           * Available Tools list.
           * Pass Tool's class or Settings object for each Tool you want to use
           */
          tools: {
            /**
             * Each Tool is a Plugin. Pass them via 'class' option with necessary settings {@link docs/tools.md}
             */
            header: {
              class: Header,
              inlineToolbar: ["link"],
              config: {
                placeholder: "Header"
              },
              shortcut: "CMD+SHIFT+H"
            },
            /**
             * Or pass class directly without any configuration
             */
            image: {
              class: SimpleImage,
              inlineToolbar: ["link"]
            },
            list: {
              class: List,
              inlineToolbar: true,
              shortcut: "CMD+SHIFT+L"
            },
            checklist: {
              class: Checklist,
              inlineToolbar: true
            },
            quote: {
              class: Quote,
              inlineToolbar: true,
              config: {
                quotePlaceholder: "Enter a quote",
                captionPlaceholder: "Quote's author"
              },
              shortcut: "CMD+SHIFT+O"
            },
            marker: {
              class: Marker,
              shortcut: "CMD+SHIFT+M"
            },
            delimiter: Delimiter,
            inlineCode: {
              class: InlineCode,
              shortcut: "CMD+SHIFT+C"
            },
            linkTool: LinkTool,
            embed: Embed,
            table: {
              class: Table,
              inlineToolbar: true,
              shortcut: "CMD+ALT+T"
            }
          },

          /**
           * Previously saved data that should be rendered
           */
          data: project.value.description
        });
      }
    });

    return {
      files,
      project
    };
  }
});
</script>

<template>
  <div class="grid grid-cols-12 gap-6">
    <div class="intro-y box bg-white col-span-12 lg:col-span-8">
      <div
        class="flex items-center p-5 border-b border-gray-200 dark:border-dark-5"
      >
        <h2 class="font-medium text-base mr-auto">
          Description
        </h2>
      </div>
      <div class="p-5">
        <div class="prose lg:prose-xl max-w-none">
          <div id="editor" />
        </div>
      </div>
    </div>
    <!-- END: Top Categories -->
    <!-- BEGIN: Work In Progress -->
    <div class="intro-y box bg-white col-span-12 lg:col-span-4">
      <div
        class="flex items-center p-5 py-5 border-b border-gray-200 dark:border-dark-5"
      >
        <h2 class="font-medium text-base mr-auto">
          Attachments
        </h2>
      </div>
      <div class="p-5">
        <div class="tab-content">
          <div
            id="work-in-progress-new"
            class="tab-content__pane active"
          >
            <div
              v-for="attachment of project.attachments"
              :key="attachment.id"
              class="w-full flex items-center justify-between border-b-2"
            >
              <div class="w-6 h-6 flex items-center">
                <base-icon
                  :name="'file/' + files.getIcon(attachment.file)"
                  size="sm"
                />
              </div>
              <div>{{ attachment.name }}</div>
              <div class="mr-2">
                <a
                  :href="attachment.file"
                  target="_blank"
                  download
                >
                  <i class="ri-file-download-fill hover:text-blue-500" />
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- END: Work In Progress -->
  </div>
</template>
